import React from "react";
import Navbar from "../components/home/navbar/Navbar";
import Footer from "../components/home/footer/Footer";
import Blog from "../components/blogs/blog/Blog";
import Bloghead from "../components/blogs/bloghead/Bloghead";
import ArticleList from "../components/articleList/ArticleList";
import * as styles from "./posts.module.css";
import { Link } from "gatsby";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { getFilteredPosts } from "./utils";
import { graphql } from "gatsby";
import SEO from "../components/Seo";
import BlogTagCloud from "../components/blogs/blogTagCloud/BlogTagCloud";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const getPageNumberPath = (currentIndex) => {
  if (currentIndex === 0) {
    return "/posts/";
  }

  return `/posts/${currentIndex + 1}/`;
};

const AllPosts = ({ pageContext, data }) => {
  const [state, setState] = React.useState({
    posts: [],
  });

  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/posts" : `/posts/${currentPage - 1}/`;
  const nextPage = `/posts/${currentPage + 1}/`;
  const allPosts = data.allMdx.edges;
  const pagedPosts = allPosts.slice(
    (currentPage - 1) * pageContext.limit,
    (currentPage - 1) * pageContext.limit + pageContext.limit
  );

  console.log(allPosts);

  React.useEffect(() => {
    setState({
      posts: pagedPosts,
    });
  }, []);

  const handleInputChange = (event) => {
    const { filteredPosts } = getFilteredPosts({
      inputValue: event?.target?.value || "",
      posts: allPosts,
    });

    if (filteredPosts.length) {
      setState({
        posts: filteredPosts,
      });
    }
  };

  return (
    <>
      <Navbar />
      <SEO titleStub={`All Posts - Sleepless Yogi Blog`} urlStub={`/posts`} />
      {/* <Bloghead /> */}
      <div className={styles.wrapper}>
        <h1 className="text-4xl">The Sleepless Yogi Blog</h1>
      </div>
      <div className={styles.searchBar}>
        <input
          className="border border-black"
          type="text"
          aria-label="Search"
          placeholder="Type to search posts..."
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.slider_main}>
          {state.posts.map((post) => {
            const postData = post.node.frontmatter;
            const blogImage = getImage(
              postData.image ? postData.image : postData.og_image.publicURL
            );

            return (
              <Blog
                key={postData.slug}
                date={postData.date}
                title={postData.title}
                description={postData.description}
                slug={postData.slug}
                blogType={postData.layout}
                blogImage={
                  postData.image ? postData.image : postData.og_image.publicURL
                }
              />
            );
          })}
        </div>
      </div>

      <div className={styles.paginationWrapper}>
        <Link
          className={isFirst ? styles.disabledButton : styles.nextPageClassName}
          to={prevPage}
          rel="prev"
        >
          <span>
            <FiChevronLeft className={styles.nextPrev} />
          </span>
        </Link>
        {Array.from({ length: numPages }, (_, i) => {
          let pageNumberClassName = styles.pageNumber;
          if (currentPage === i + 1) {
            pageNumberClassName = styles.currentPageNumber;
          }

          return (
            <Link key={i + 1} to={getPageNumberPath(i)}>
              <span
                className={
                  pageNumberClassName
                    ? pageNumberClassName
                    : styles.paginationNumber
                }
              >
                {i + 1}
              </span>
            </Link>
          );
        })}
        <Link
          className={isLast ? styles.disabledButton : styles.nextPageClassName}
          to={nextPage}
          rel="next"
        >
          <span>
            <FiChevronRight className={styles.nextPrev} />
          </span>
        </Link>
      </div>
      <BlogTagCloud />
      <ArticleList themeClass={"ternary"} />
      <Footer />
    </>
  );
};

export default AllPosts;

export const pageQuery = graphql`
  # query AllPostsQuery($skip: Int!, $limit: Int!) {
  query AllPostsQuery {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC } # skip: $skip # limit: $limit
      filter: { frontmatter: { layout: { eq: "post" } } }
    ) {
      edges {
        node {
          frontmatter {
            date
            slug
            title
            tags
            description
            layout
            og_image {
              publicURL
            }
            image
          }
        }
      }
    }
  }
`;
